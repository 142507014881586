import React from "react";
import { Link } from "gatsby";

export default class SocialIcon extends React.Component {

  copyToClipboard(textLink) {
    const el = document.createElement('textarea');
    el.value = textLink;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    alert("Link copied to your clipboard!")
  }

  render() {
    // Pass a urlLink prop to set the link for the clipboard icon
    let siteData;
    if (this.props.siteData) {
      siteData = this.props.siteData;
    } else {
      console.log('NEED SITE DATA!')
    }

    let iconLinkType = 'direct';
    let linkDestination = 'outbound';
    if (this.props.iconLinkType) {
      iconLinkType = this.props.iconLinkType;
    }

    let iconUrl;
    if (iconLinkType === 'direct') {
      if (this.props.icon === 'twitter') {
        iconUrl = siteData.twitterUrl;
      } else if (this.props.icon === 'goodreads') {
        iconUrl = siteData.goodreadsUrl;
      } else if (this.props.icon === 'facebook') {
        iconUrl = siteData.facebookUrl;
      } else if (this.props.icon === 'linkedin') {
        iconUrl = siteData.linkedinUrl;
      } else if (this.props.icon === 'reddit') {
        iconUrl = siteData.redditUrl;
      } else if (this.props.icon === 'github') {
        iconUrl = siteData.githubUrl;
      } else if (this.props.icon === 'instagram') {
        iconUrl = siteData.instagramUrl;
      } else if (this.props.icon === 'discord') {
        iconUrl = siteData.discordUrl;
      } else if (this.props.icon === 'twitch') {
        iconUrl = siteData.twitchUrl;
      } else if (this.props.icon === 'email') {
        iconUrl = `mailto:${siteData.emailAddress}`
      } else if (this.props.icon === 'newsletter') {
        iconUrl = siteData.newsletterUrl;
        linkDestination = 'inbound'
      } else if (this.props.icon === 'rss') {
        iconUrl = siteData.rssUrl
      } else if (this.props.icon === 'url-link') {
        if (!this.props.urlLink) {
          throw Error('Need a URL to share');
        }
        iconUrl = `${siteData.siteUrl}/${this.props.urlLink}`;
      } else {
        throw Error('Icon with link type "direct" not setup for this social icon');
      }
    } else if (iconLinkType === 'share') {
      if (!this.props.urlLink) {
        throw Error('Need a URL to share');
      }
      if (this.props.icon === 'twitter') {
        iconUrl = `https://twitter.com/intent/tweet?text=Check out this story: ${siteData.siteUrl}/${this.props.urlLink}`
      } else if (this.props.icon === 'facebook') {
        iconUrl = `https://www.facebook.com/sharer/sharer.php?u=${siteData.siteUrl}/${this.props.urlLink}`
      } else if (this.props.icon === 'email') {
        iconUrl = `mailto:?to=&subject=New Story by Zack Thoutt&body=Check out this story: ${siteData.siteUrl}/${this.props.urlLink}`
      } else if (this.props.icon === 'url-link') {
        iconUrl = `${siteData.siteUrl}/${this.props.urlLink}`;
        linkDestination = 'clipboard';
      } else {
        throw Error('Icon with link type "share" not setup for this social icon');
      }
    }

    return (
      <>
        {linkDestination === 'outbound' && (
          <a className="cursor-pointer hover:text-lightblue active:text-black" href={iconUrl} target="_blank" rel="noopener noreferrer">
            <div className="relative flex justify-start items-center">
              {this._getIconSVG()}
              <div className="flex justify-start items-center">
                {this.props.children}
              </div>
            </div>
          </a>
        )}
        {linkDestination === 'inbound' && (
          <Link className="cursor-pointer hover:text-lightblue active:text-black" to={iconUrl}>
            <div className="relative flex justify-start items-center">
              {this._getIconSVG()}
              <div className="flex justify-start items-center">
                {this.props.children}
              </div>
            </div>
          </Link>
        )}
        {linkDestination === 'clipboard' && (
          <div role="button" onClick={() => this.copyToClipboard(iconUrl)} onKeyDown={() => this.copyToClipboard(iconUrl)} className="cursor-pointer hover:text-lightblue active:text-black active:text-lightblue">
            <div className="relative flex justify-center items-center">
              {this._getIconSVG()}
              <div className="flex justify-start items-center">
                {this.props.children}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  _getIconSVG() {
    return (
      <>
        {this.props.icon === 'twitter' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18.862 9.23676C19.07 13.8538 15.627 19.0018 9.532 19.0018C7.678 19.0018 5.953 18.4588 4.5 17.5278C6.242 17.7328 7.98 17.2488 9.36 16.1678C7.923 16.1408 6.711 15.1928 6.294 13.8878C6.809 13.9868 7.315 13.9568 7.776 13.8318C6.196 13.5148 5.108 12.0928 5.143 10.5718C5.585 10.8178 6.091 10.9658 6.629 10.9828C5.168 10.0058 4.754 8.07576 5.612 6.59976C7.232 8.58576 9.651 9.89276 12.379 10.0298C11.9 7.97676 13.458 5.99976 15.577 5.99976C16.521 5.99976 17.374 6.39776 17.972 7.03676C18.72 6.88976 19.424 6.61676 20.057 6.24076C19.813 7.00776 19.292 7.65076 18.615 8.05676C19.279 7.97676 19.912 7.80076 20.5 7.53976C20.059 8.19576 19.503 8.77376 18.862 9.23676ZM0 23.9998H24V-0.000244141H0V23.9998Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'goodreads' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00715 8.98792C8.22697 5.71449 10.1208 4.26588 11.8963 4.28346C14.3244 4.27291 15.6129 5.99225 15.941 8.49216C16.2081 10.4963 15.6975 12.7395 14.0877 13.9069C12.9379 14.7402 11.3586 14.6663 10.4252 14.2127C8.50767 13.2845 7.8685 11.0624 8.00715 8.98792Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24 0H0V24H24V0ZM7.04333 17.1803H6.94864C6.98922 17.7499 7.11773 18.2703 7.38828 18.7449C8.63956 20.9249 11.6663 21.3503 14.0167 20.7561C16.597 20.1056 17.4966 17.8132 17.5 15.0531C17.5 15.0531 17.4966 13.2283 17.4966 3.37281H15.9985V5.53869C15.9613 5.55275 15.9342 5.51056 15.9173 5.47188C15.4202 4.36081 14.0607 2.98604 11.9876 3.00011C9.30578 3.0212 7.47959 4.67726 6.78293 7.17716C6.56311 7.97881 6.48532 8.79453 6.50223 9.62784C6.59016 13.819 8.83571 15.9637 12.319 15.8231C13.8104 15.7633 15.1361 14.9089 15.8835 13.3935C15.897 13.3671 15.9114 13.3416 15.9258 13.3162C15.9401 13.2907 15.9545 13.2652 15.968 13.2388L15.9985 13.2494C16.0154 13.4533 16.0086 14.9019 16.0052 15.1058C15.9951 15.904 15.9038 16.688 15.6332 17.444C15.2274 18.5726 14.4766 19.3075 13.3336 19.5677C12.4137 19.7786 11.4938 19.7716 10.5841 19.5044C9.47149 19.1774 8.69705 18.4847 8.45694 17.2576C8.44211 17.1806 8.40129 17.1819 8.35498 17.1834C8.34847 17.1836 8.34186 17.1838 8.33519 17.1838C7.9057 17.1803 7.47282 17.1803 7.04333 17.1803Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'facebook' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16 7H14.077C13.461 7 13 7.252 13 7.889V9H16L15.761 12H13V20H10V12H8V9H10V7.077C10 5.055 11.064 4 13.461 4H16V7ZM0 24H24V0H0V24Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'linkedin' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.397 7.179 20 6.988 20 12.241V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM5 19H8V8H5V19ZM0 24H24V0H0V24Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'reddit' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.626 13.063C10.626 12.483 10.153 12.013 9.572 12.013C8.991 12.013 8.517 12.483 8.517 13.063C8.517 13.641 8.99 14.112 9.572 14.112C10.153 14.113 10.626 13.641 10.626 13.063ZM20 11.853C20 10.88 19.205 10.088 18.228 10.088C17.751 10.088 17.32 10.279 17.001 10.585C15.794 9.792 14.161 9.287 12.354 9.222L13.343 6.108L16.02 6.736L16.016 6.775C16.016 7.57 16.666 8.217 17.465 8.217C18.263 8.217 18.913 7.57 18.913 6.775C18.913 5.98 18.263 5.334 17.465 5.334C16.852 5.334 16.329 5.716 16.118 6.252L13.232 5.577C13.106 5.545 12.978 5.619 12.939 5.743L11.836 9.213C9.944 9.236 8.23 9.746 6.969 10.563C6.653 10.271 6.233 10.089 5.769 10.089C4.794 10.088 4 10.879 4 11.853C4 12.5 4.355 13.059 4.878 13.367C4.844 13.554 4.821 13.745 4.821 13.939C4.821 16.545 8.027 18.667 11.967 18.667C15.908 18.667 19.113 16.545 19.113 13.939C19.113 13.755 19.094 13.577 19.064 13.399C19.618 13.099 20 12.522 20 11.853ZM0 24H24V0H0V24ZM14.432 12.013C13.851 12.013 13.378 12.483 13.378 13.063C13.378 13.641 13.851 14.112 14.432 14.112C15.013 14.112 15.486 13.64 15.486 13.063C15.486 12.483 15.013 12.013 14.432 12.013ZM14.558 15.827C14.655 15.922 14.655 16.08 14.558 16.175C14.027 16.705 13.193 16.961 12.009 16.961L12 16.959L11.991 16.961C10.806 16.961 9.973 16.705 9.442 16.175C9.345 16.08 9.345 15.922 9.442 15.827C9.538 15.731 9.696 15.731 9.793 15.827C10.226 16.257 10.945 16.467 11.992 16.467L12.001 16.469L12.01 16.467C13.056 16.467 13.775 16.257 14.209 15.827C14.304 15.73 14.461 15.73 14.558 15.827Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'github' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.534 19.5898C14.128 19.6678 14 19.4188 14 19.2058V17.0108C14 16.2638 13.738 15.7778 13.45 15.5298C15.232 15.3318 17.103 14.6548 17.103 11.5828C17.103 10.7088 16.793 9.99476 16.28 9.43576C16.363 9.23376 16.637 8.41976 16.201 7.31876C16.201 7.31876 15.53 7.10376 14.003 8.13876C13.364 7.95876 12.68 7.87176 12 7.86776C11.32 7.87076 10.636 7.95876 9.997 8.13676C8.469 7.10176 7.797 7.31676 7.797 7.31676C7.363 8.41876 7.637 9.23176 7.72 9.43476C7.208 9.99476 6.896 10.7078 6.896 11.5818C6.896 14.6468 8.763 15.3328 10.541 15.5358C10.312 15.7358 10.105 16.0878 10.033 16.6068C9.576 16.8098 8.419 17.1628 7.705 15.9408C7.705 15.9408 7.282 15.1718 6.478 15.1148C6.478 15.1148 5.698 15.1058 6.423 15.6018C6.423 15.6018 6.948 15.8478 7.312 16.7728C7.312 16.7728 7.775 18.1998 10 17.7158V19.2048C10 19.4158 9.871 19.6638 9.472 19.5898C6.292 18.5328 4 15.5338 4 11.9998C4 7.58076 7.582 3.99976 12 3.99976C16.418 3.99976 20 7.58076 20 11.9998C20 15.5328 17.711 18.5308 14.534 19.5898ZM0 23.9998H24V-0.000244141H0V23.9998Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'instagram' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20 12C20 9.828 19.991 9.555 19.952 8.702C19.821 5.8 18.207 4.182 15.299 4.049C14.445 4.009 14.173 4 12 4C9.827 4 9.556 4.009 8.702 4.048C5.796 4.181 4.182 5.793 4.048 8.701C4.009 9.555 4 9.828 4 12C4 14.173 4.009 14.445 4.048 15.298C4.182 18.204 5.794 19.819 8.702 19.952C9.556 19.991 9.827 20 12 20C14.173 20 14.445 19.991 15.299 19.952C18.201 19.819 19.821 18.207 19.952 15.298C19.991 14.445 20 14.173 20 12ZM0 24H24V0H0V24ZM17.23 7.73C17.23 7.2 16.8 6.77 16.27 6.77C15.74 6.77 15.31 7.2 15.31 7.73C15.31 8.26 15.74 8.69 16.27 8.69C16.801 8.69 17.23 8.26 17.23 7.73ZM16.108 12C16.108 9.731 14.268 7.892 12 7.892C9.731 7.892 7.892 9.731 7.892 12C7.892 14.269 9.732 16.108 12 16.108C14.269 16.108 16.108 14.269 16.108 12ZM18.513 8.768C18.551 9.611 18.559 9.864 18.559 12C18.559 14.136 18.551 14.389 18.513 15.233C18.413 17.383 17.404 18.414 15.234 18.512C14.39 18.55 14.137 18.559 12 18.559C9.864 18.559 9.61 18.551 8.768 18.513C6.594 18.414 5.587 17.381 5.489 15.234C5.45 14.389 5.441 14.136 5.441 12C5.441 9.864 5.45 9.611 5.488 8.768C5.587 6.616 6.597 5.587 8.767 5.489C9.611 5.45 9.864 5.442 12 5.442C14.136 5.442 14.39 5.45 15.233 5.488C17.401 5.587 18.413 6.616 18.513 8.768ZM14.667 12C14.667 13.473 13.473 14.667 12 14.667C10.527 14.667 9.333 13.474 9.333 12C9.333 10.527 10.527 9.333 12 9.333C13.473 9.333 14.667 10.527 14.667 12Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'discord' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.90875 13.0212C7.90875 13.9392 8.55833 14.6866 9.34668 14.6866C10.1476 14.6866 10.7846 13.9392 10.7846 13.0212C10.7972 12.1098 10.1539 11.3558 9.34668 11.3558C8.54574 11.3558 7.90875 12.1033 7.90875 13.0212Z" fill="currentColor"/>
            <path d="M13.2253 13.0212C13.2253 13.9392 13.8748 14.6866 14.6632 14.6866C15.4704 14.6866 16.1011 13.9392 16.1011 13.0212C16.1137 12.1098 15.4704 11.3558 14.6632 11.3558C13.8622 11.3558 13.2253 12.1033 13.2253 13.0212Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24 0H0V24H24V0ZM14.2879 6.09668C15.4313 6.30059 16.5249 6.65662 17.5447 7.13985C17.5535 7.14378 17.5611 7.15035 17.5655 7.15952C19.3723 9.90416 20.2647 13.0002 19.9311 16.5645C19.9298 16.5795 19.9223 16.5933 19.9103 16.6025C18.5417 17.6404 17.2161 18.2705 15.9087 18.6882C15.8879 18.6947 15.8652 18.6869 15.8526 18.6685C15.5505 18.2344 15.2762 17.7768 15.0359 17.2962C15.022 17.268 15.0346 17.2339 15.063 17.2228C15.4988 17.0529 15.9131 16.8497 16.3117 16.6091C16.3433 16.59 16.3451 16.5435 16.3161 16.5212C16.2316 16.4563 16.1478 16.3881 16.0677 16.3199C16.0525 16.3074 16.0323 16.3048 16.0153 16.3133C13.4277 17.548 10.5929 17.548 7.97435 16.3133C7.95732 16.3055 7.93713 16.3081 7.92264 16.3205C7.84255 16.3887 7.75865 16.4563 7.67477 16.5212C7.64577 16.5435 7.64829 16.59 7.67982 16.6091C8.0784 16.8451 8.49275 17.0529 8.92791 17.2234C8.95628 17.2346 8.96953 17.268 8.95565 17.2962C8.72043 17.7775 8.44608 18.2351 8.13832 18.6692C8.12507 18.6869 8.10301 18.6947 8.08219 18.6882C6.78114 18.2705 5.45547 17.6404 4.08693 16.6025C4.07558 16.5933 4.06738 16.5789 4.06612 16.5638C3.78737 13.4809 4.35559 10.3592 6.42986 7.15887C6.43488 7.15035 6.44246 7.14378 6.45129 7.13985C7.47171 6.65596 8.56527 6.29994 9.70803 6.09668C9.72886 6.0934 9.74966 6.10324 9.76038 6.12225C9.90166 6.38058 10.0631 6.7117 10.1722 6.98248C11.3768 6.79234 12.6003 6.79234 13.8301 6.98248C13.9392 6.71759 14.0949 6.38058 14.2356 6.12225C14.2463 6.10258 14.2671 6.09274 14.2879 6.09668Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'twitch' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24 0H0V24H24V0ZM7.33333 3L4 6.39286V18.6071H8V22L11.3333 18.6071H14L20 12.5V3H7.33333ZM18.6667 11.8214L16 14.5357H13.3333L11 16.9107V14.5357H8V4.35714H18.6667V11.8214ZM16.6667 6.73214H15.3333V10.8036H16.6667V6.73214ZM11.6667 6.73214H13V10.8036H11.6667V6.73214Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'email' && (
          <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.015 0L11.999 9.713L23.985 0H0.015ZM11.999 12.287L0 2.562V18H24V2.562L11.999 12.287Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'newsletter' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24 0H0V24H24V0ZM6.6666 10V16H13.3334V10C13.3334 8.159 11.8404 6.6668 10 6.6668C8.1596 6.6668 6.6666 8.159 6.6666 10ZM17.3334 6.7336V5.3332H19.3334L18.6666 4H16V6.6668H13.2598C14.1264 7.5144 14.6666 8.6946 14.6666 10V16V20H16V16H20V10C20 8.3876 18.8542 7.043 17.3334 6.7336ZM4 20L5.7774 17.3334H12.444L10.6666 20H4Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'url-link' && (
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0235 1.8825L10.0945 4.8115C11.3715 4.7155 12.6665 4.9075 13.8735 5.3855L15.1995 4.0595C16.5055 2.7525 18.6315 2.7525 19.9395 4.0595C21.2465 5.3665 21.2465 7.4925 19.9395 8.7995L15.6335 13.1055C14.3235 14.4155 12.1935 14.4045 10.8945 13.1055C10.5905 12.8015 10.3305 12.4245 10.1675 12.0535L7.9205 14.2985C8.1575 14.6565 8.4015 14.9655 8.7165 15.2805C9.5285 16.0925 10.5635 16.6975 11.7525 16.9855C13.2945 17.3565 14.9475 17.1515 16.3665 16.3675C16.8835 16.0815 17.3715 15.7195 17.8095 15.2805L22.1145 10.9755C24.6265 8.4645 24.6265 4.3935 22.1155 1.8825C19.6045 -0.6275 15.5345 -0.6275 13.0235 1.8825ZM6.1875 8.7185C6.6265 8.2795 7.1125 7.9175 7.6305 7.6315C10.5185 6.0405 14.2195 6.8865 16.0765 9.7005L13.8305 11.9455C13.1855 10.4765 11.5865 9.6405 9.9965 9.9965C9.3975 10.1305 8.8285 10.4295 8.3625 10.8945L4.0595 15.2005C2.7525 16.5075 2.7525 18.6335 4.0595 19.9415C5.3665 21.2475 7.4925 21.2475 8.7985 19.9415L10.1265 18.6135C11.3335 19.0925 12.6275 19.2835 13.9055 19.1895L10.9765 22.1175C8.4645 24.6285 4.3945 24.6285 1.8825 22.1175C-0.6275 19.6075 -0.6275 15.5355 1.8825 13.0255L6.1875 8.7185Z" fill="currentColor"/>
          </svg>
        )}
        {this.props.icon === 'rss' && (
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 100 100"><g><path d="M2.5,2.5v95h95v-95H2.5z M26,79.6c-3.1,0-5.6-2.5-5.6-5.7c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.7,2.5,5.7,5.6    C31.7,77.1,29.1,79.6,26,79.6z M41.1,74c0-8.3-6.8-15.1-15.1-15.1v-6.5c11.9,0,21.6,9.7,21.6,21.6H41.1z M57.1,74    c0-17.1-13.9-31-31.1-31v-6.5c20.7,0,37.6,16.9,37.6,37.6H57.1z M73.1,74c0-25.9-21.1-47-47.1-47v-6.5c29.6,0,53.6,24,53.6,53.6    H73.1z" fill="currentColor"></path></g></svg>
        )}
      </>
    )
  }

}