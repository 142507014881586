import React from "react";
import { graphql } from "gatsby";
import BaseLayout from "../../../components/layout"
import Seo from "../../../components/seo";
import { StaticImage } from "gatsby-plugin-image"
import NomadItinerary from "../../../../nomad-itinerary";
import HorizontalRule from "../../../components/horizontal-rule";
import TextBlock from "../../../components/text-block";
import BookTOCCard from "../../../components/book-toc-card";
import ChapterSummary from "../../../components/chapter-summary";
import BookPageHeader from "../../../components/book-page-header";
import SocialIcon from "../../../components/social-icon";


export default class WritingNomadLifePage extends React.Component {
  state = {
    showCompleteSegments: true
  };

  handleCompleteToggleChange = event => {
    this.setState({
      showCompleteSegments: !this.state.showCompleteSegments
    })
  }

  render() {
    const site = this.props.data.site;
    const nomadLife = this.props.data.nomadLife.nodes[0];
    const nomadLifeChapters = this.props.data.allMdx.nodes;

    let breadcrumbList = [
      [
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'Library',
          url: '/writing/library/'
        },
        {
          title: 'Nomad Life',
        }
      ]
    ]

    return (
      <BaseLayout>
        <Seo title="Nomad Life" desc={nomadLife.seo_description} pathname={this.props.location.pathname} breadcrumbList={breadcrumbList}></Seo>

        <BookPageHeader book={nomadLife} chapters={nomadLifeChapters} breadcrumbTrail={breadcrumbList[0]}></BookPageHeader>

        <div className="layout-base-w app-px-base">
          <div className="mt-40">
            <h3 className="text-header-2 text-mint text-center mb-16">Our Nomad Life</h3>
            <div className="app-reading-w flex justify-between gap-8 mx-auto [width:100%] sm:[width:460px]">
              <div className="flex-1">
                <StaticImage className="w-full block app-image" src="https://media.zackthoutt.com/img/zack-headshot.jpeg" alt="Zack Headshot" />
                <h3 className="text-center text-subheader-3">Zack</h3>
              </div>
              <div className="flex-1">
                <StaticImage className="w-full block app-image" src="https://media.zackthoutt.com/img/shelby-headshot.jpeg" alt="Shelby Headshot" />
                <h3 className="text-center text-subheader-3">Shelby</h3>
              </div>
              <div className="flex-1">
                <StaticImage className="w-full block app-image" src="https://media.zackthoutt.com/img/skutull-headshot.jpeg" alt="Skutull Headshot" />
                <h3 className="text-center text-subheader-3">Skutull</h3>
              </div>
            </div>

            <div className="layout-reading-w mt-24 mb-12">
              <TextBlock text={nomadLife.summary}></TextBlock>
            </div>

            <div className="my-20 pb-10 layout-reading-w md:flex justify-start items-start">
              <div className="md:mr-20 mb-12 md:mb-0 text-center md:text-left text-mint">
                <h6 className="text-subheader-1 mb-4">Stats</h6>
                <h6 className="text-header-2 mb-4">Normal Humans: 2</h6>
                <h6 className="text-header-2 mb-4">Furry Humans: 1</h6>
                <h6 className="text-header-2 mb-4">Days Nomadic: 441</h6>
                <h6 className="text-header-2 mb-4">Miles Traveled: 34,734</h6>
              </div>
              <div className="md:my-0 mt-16 text-center md:text-left text-mint">
                <h6 className="text-subheader-1 mb-4">Follow</h6>
                <div className="mb-4 flex justify-center md:justify-start">
                  <SocialIcon icon="newsletter" siteData={site.siteMetadata}><h6 className="text-header-2 ml-2 mt-2">Newsletter</h6></SocialIcon>
                </div>
                <div className="mb-4 flex justify-center md:justify-start">
                  <SocialIcon icon="twitter" siteData={site.siteMetadata}><h6 className="text-header-2 ml-2 mt-2">Twitter</h6></SocialIcon>
                </div>
                <div className="mb-4 flex justify-center md:justify-start">
                  <SocialIcon icon="instagram" siteData={site.siteMetadata}><h6 className="text-header-2 ml-2 mt-2">Instagram</h6></SocialIcon>
                </div>
              </div>
            </div>

            <div className="my-40">
              <HorizontalRule ruleType="100"></HorizontalRule>
            </div>

            <BookTOCCard chapters={nomadLifeChapters}></BookTOCCard>

            <div className="my-40 text-mint">
              <HorizontalRule ruleType="4"></HorizontalRule>
            </div>

            <div className="my-40 layout-reading-w" id="journey-map">
              <h3 className="text-header-2 text-mint text-center mb-8">Map of our journey</h3>
              <iframe className="w-full md:[width:620px]" title="Live Map" src="https://www.google.com/maps/d/u/0/embed?mid=1VdB5n5o06gmrbfBB3gI8JN27g9CG4Zyz" height="443"></iframe>
            </div>

            <div className="mt-20 mb-10 layout-base-w" id="itinerary">
              <div className="flex justify-start sm:justify-between w-full items-start sm:items-end flex-col sm:flex-row">
                <h3 className="text-header-2 text-mint mb-8 sm:mb-4">Itinerary</h3>
                <div className="mb-8 sm:mb-4">
                  <label className="flex items-center cursor-pointer">
                    <div className="mr-3 text-subheader-3">
                      Show Completed Segments
                    </div>
                    <div className="relative">
                      <input type="checkbox" id="toggleB" className="sr-only" checked={this.state.showCompleteSegments} onChange={this.handleCompleteToggleChange}></input>
                      <div className="block border border-mint rounded-full" style={{'width': '56px', 'height': '28px'}}></div>
                      <div className="dot absolute bg-black rounded-full transition" style={{'height': '14px', 'width': '14px', 'left': '9px', 'top': '7px'}}></div>
                    </div>
                  </label>
                </div>
              </div>

              <table className="table-fixed min-w-full divide-y divide-mint">
                <thead>
                  <tr>
                    <th scope="col" className="w-7/12 px-6 py-3 text-left tracking-wider text-subheader-3">Location</th>
                    <th scope="col" className="w-4/12 px-6 py-3 text-left tracking-wider text-subheader-3">Dates</th>
                    <th scope="col" className="w-1/12 px-6 py-3 text-left tracking-wider text-subheader-3">Booked</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-mint">
                  {NomadItinerary.NomadItinerary.map((item) => (
                    <tr key={`${item.location}${item.date}`} className={item.complete ? "text-gray-400" : ""}>
                      {(this.state.showCompleteSegments || !item.complete) &&
                        <>
                          <td className="px-6 py-2 text-subheader-3 sm:text-subheader-1 text-mint">{item.location}</td>
                          <td className="px-6 py-2 text-subheader-3">{item.date}</td>
                          {item.booked &&
                            <td className="px-6 py-2"><div className="border-2 border-mint bg-mint" style={{'height': '12px', 'width': '12px'}}></div></td>
                          }
                          {!item.booked &&
                            <td className="px-6 py-2"><div className="border-2 border-mint" style={{'height': '12px', 'width': '12px'}}></div></td>
                          }
                        </>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="my-40 text-mint">
              <HorizontalRule ruleType="2"></HorizontalRule>
            </div>

            <div className="flex justify-center my-40">
              <ChapterSummary chapter={nomadLifeChapters[0]} summaryType="cta"></ChapterSummary>
            </div>

          </div>
        </div>
      </BaseLayout>
    )
  }
}

export const query = graphql`
  query WritingNomadLife {
    allMdx(
      sort: { fields: [frontmatter___chapter], order: ASC }
      filter: { frontmatter: { published: { eq: true }, record_type: { eq: "story" }, collection: { eq: "Nomad Life" } } }
    ) {
      nodes {
        ...ArticleSnippet
      }
    }
    
    site {
      siteMetadata {
        ...CommonMetaData
        company
      }
    }
    
    nomadLife: allCollections(
      filter: { title: { eq: "Nomad Life" } }
      limit: 1
    ) {
      nodes {
        ...CollectionFields
      }
    }
  }
`;
