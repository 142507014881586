const NomadItinerary = [
  {
    "location": "Boulder, CO",
    "date": "Jan 20, 1993 - Mar 1, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Dolores, CO",
    "date": "Mar 2 - Mar 14, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Santa Fe, NM",
    "date": "Mar 15 - Mar 24, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Scottsdale, AZ",
    "date": "Mar 25 - Mar 30, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "San Diego, CA",
    "date": "Mar 31 - Apr 2, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Monterray, CA",
    "date": "Apr 3 - Apr 11, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Cloverdale, CA",
    "date": "Apr 12 - Apr 18, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Port Orford, OR",
    "date": "Apr 21 - May 16, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Boulder, CO",
    "date": "May 18 - May 27, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Jacksonville, FL",
    "date": "May 31 - Jul 3, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Cashiers, NC",
    "date": "Jul 4 - Jul 16, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "New York City, NY",
    "date": "Jul 18 - Aug 20, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Wellsboro, PA",
    "date": "Aug 20 - Aug  22, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Washington DC",
    "date": "Aug 22 - Sep  20, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Frankfurt, Germany",
    "date": "Sep  21 - Sep 29, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Hallstatt, Austria",
    "date": "Sep  29 - Oct 6, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Ljubjliana, Slovenia",
    "date": "Oct 6 - Nov 5, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Šibenik, Croatia",
    "date": "Nov 6 - Nov 19, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Motovun, Croatia",
    "date": "Nov 20 - Nov 26, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Ljubljana, Slovenia",
    "date": "Nov 27 - Dec 4, 2021",
    "booked": true,
    "complete": true
  },
  {
    "location": "Vienna, Austria",
    "date": "Dec 5, 2021 - Jan 3, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "Kensington, London, England",
    "date": "Jan 5 - Feb 3, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "Twickenham, London, England",
    "date": "Feb 3 - March 5, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "Dublin, Ireland",
    "date": "Mar 5 - Mar 11, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "Cleggan, Ireland",
    "date": "Mar 12 - Apr 8, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "Banbridge, North Ireland",
    "date": "Apr 9, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "Western Isles of Scotland",
    "date": "Apr 10 - 21, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "West Highland Way, Scotland",
    "date": "Apr 22 - May 2, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "Brecon Beacons, Wales",
    "date": "May 3 - May 5, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "London, England",
    "date": "May 5 - May 6, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "Queen Mary II, International Waters",
    "date": "May 8 - May 15, 2022",
    "booked": true,
    "complete": true
  },
  {
    "location": "Road Trip → NYC to Boulder",
    "date": "May 16 - May 21, 2022",
    "booked": true,
    "complete": true
  },
];

exports.NomadItinerary = NomadItinerary;